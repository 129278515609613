import React, { useState, useEffect, useRef } from 'react'
import { Trash2, RotateCcw, Play, Pause, Square, Coffee, BarChart, X } from "lucide-react"
import './App.css'

type TimerMode = 'work' | 'relax'

interface Task {
  id: number
  text: string
  completed: boolean
}

interface TimeEntry {
  mode: TimerMode
  startTime: Date
  duration: number
}

const Logo = () => (
  <span className="text-2xl font-thin">domopomo</span>
)

export default function Domopomo() {
  const [workDuration, setWorkDuration] = useState(35)
  const [relaxDuration, setRelaxDuration] = useState(10)
  const [timeLeft, setTimeLeft] = useState(workDuration * 60)
  const [isActive, setIsActive] = useState(false)
  const [mode, setMode] = useState<TimerMode>('work')
  const [tasks, setTasks] = useState<Task[]>([])
  const [newTask, setNewTask] = useState('')
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([])
  const [showReport, setShowReport] = useState(false)
  const [showFika, setShowFika] = useState(false)

  const todoInputRef = useRef<HTMLInputElement>(null)
  const currentEntryRef = useRef<TimeEntry | null>(null)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (isActive) {
      if (!currentEntryRef.current) {
        currentEntryRef.current = {
          mode,
          startTime: new Date(),
          duration: 0
        }
      }
      interval = setInterval(() => {
        setTimeLeft((time) => {
          if (time > 0) {
            if (currentEntryRef.current) {
              currentEntryRef.current.duration += 1
            }
            return time - 1
          }
          setIsActive(false)
          finishCurrentEntry()
          return mode === 'work' ? relaxDuration * 60 : workDuration * 60
        })
      }, 1000)
    } else {
      finishCurrentEntry()
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isActive, mode, workDuration, relaxDuration])

  const finishCurrentEntry = () => {
    if (currentEntryRef.current) {
      setTimeEntries(prev => [...prev, currentEntryRef.current!])
      currentEntryRef.current = null
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const handleStart = (timerMode: TimerMode) => {
    finishCurrentEntry()
    setMode(timerMode)
    setTimeLeft(timerMode === 'work' ? workDuration * 60 : relaxDuration * 60)
    setIsActive(true)
  }

  const handlePause = () => {
    setIsActive(false)
  }

  const handleStop = (timerMode: TimerMode) => {
    setIsActive(false)
    setMode(timerMode)
    setTimeLeft(timerMode === 'work' ? workDuration * 60 : relaxDuration * 60)
    finishCurrentEntry()
  }

  const handleFika = () => {
    setMode('relax')
    setTimeLeft(60 * 60)
    setIsActive(true)
    finishCurrentEntry()
    setShowFika(false)
  }

  const handleAddTask = (e: React.FormEvent) => {
    e.preventDefault()
    if (newTask.trim()) {
      setTasks([...tasks, { id: Date.now(), text: newTask.trim(), completed: false }])
      setNewTask('')
      todoInputRef.current?.focus()
    }
  }

  const handleToggleTask = (id: number) => {
    setTasks(tasks.map(task => 
      task.id === id ? { ...task, completed: !task.completed } : task
    ))
  }

  const handleDeleteTask = (id: number) => {
    setTasks(tasks.filter(task => task.id !== id))
  }

  const TimerCard = ({ duration, setDuration, color, timerMode }: { duration: number, setDuration: (value: number) => void, color: string, timerMode: TimerMode }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const isCurrentMode = mode === timerMode

    return (
      <div className={`bg-${color}-50 mb-2 rounded-lg border border-${color}-200 shadow-sm`}>
        <div className="p-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={`w-10 h-10 rounded-full bg-${color}-200 flex items-center justify-center text-${color}-800 font-bold cursor-pointer hover:bg-${color}-300 transition-colors`}
              >
                {timerMode === 'work' ? 'W' : 'R'}
              </button>
              <div className={`text-2xl font-bold text-${color}-800`} aria-live="polite">
                {formatTime(isCurrentMode ? timeLeft : duration * 60)}
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {isActive && isCurrentMode ? (
                <button 
                  onClick={() => handleStop(timerMode)}
                  className={`p-2 rounded-md bg-${color}-200 text-${color}-800 hover:bg-${color}-300 hover:text-${color}-900`}
                >
                  <Square className="h-4 w-4" />
                </button>
              ) : (
                <button 
                  onClick={() => handleStart(timerMode)}
                  disabled={isActive && !isCurrentMode}
                  className={`p-2 rounded-md bg-${color}-200 text-${color}-800 hover:bg-${color}-300 hover:text-${color}-900 disabled:opacity-50`}
                >
                  <Play className="h-4 w-4" />
                </button>
              )}
              <button 
                onClick={handlePause}
                disabled={!isActive || !isCurrentMode}
                className={`p-2 rounded-md bg-${color}-200 text-${color}-800 hover:bg-${color}-300 hover:text-${color}-900 disabled:opacity-50`}
              >
                <Pause className="h-4 w-4" />
              </button>
              <button 
                onClick={() => handleStop(timerMode)}
                className={`p-2 rounded-md bg-${color}-200 text-${color}-800 hover:bg-${color}-300 hover:text-${color}-900`}
              >
                <RotateCcw className="h-4 w-4" />
              </button>
            </div>
          </div>
          {isExpanded && (
            <div className="mt-4">
              <input
                type="range"
                min={1}
                max={60}
                step={1}
                value={duration}
                onChange={(e) => {
                  setDuration(Number(e.target.value))
                  if (!isActive && isCurrentMode) {
                    setTimeLeft(Number(e.target.value) * 60)
                  }
                }}
                className={`w-full h-2 bg-${color}-200 rounded-lg appearance-none cursor-pointer`}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const DailyReport = () => {
    const totalWorkTime = timeEntries.filter(entry => entry.mode === 'work').reduce((acc, entry) => acc + entry.duration, 0)
    const totalRelaxTime = timeEntries.filter(entry => entry.mode === 'relax').reduce((acc, entry) => acc + entry.duration, 0)
    const totalTime = totalWorkTime + totalRelaxTime

    const workPercentage = totalTime > 0 ? (totalWorkTime / totalTime) * 100 : 0
    const relaxPercentage = totalTime > 0 ? (totalRelaxTime / totalTime) * 100 : 0

    const graphWidth = 300
    const graphHeight = 100
    const barWidth = 4
    const maxBarHeight = 80

    const sortedEntries = [...timeEntries].sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
    const startTime = sortedEntries.length > 0 ? sortedEntries[0].startTime : new Date()
    const endTime = sortedEntries.length > 0 ? new Date(Math.max(...sortedEntries.map(e => e.startTime.getTime() + e.duration * 1000))) : new Date()
    const totalDuration = (endTime.getTime() - startTime.getTime()) / 1000

    const completedTasks = tasks.filter(task => task.completed)
    const incompleteTasks = tasks.filter(task => !task.completed)

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
        <div className="bg-white w-full max-w-md p-4 rounded-lg m-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Daily Report</h2>
            <button onClick={() => setShowReport(false)} className="text-gray-500 hover:text-gray-700">
              <X className="h-6 w-6" />
            </button>
          </div>
          <div className="space-y-4">
            <div className="flex justify-between">
              <div>
                <h3 className="font-semibold">Total Work Time</h3>
                <p>{formatTime(totalWorkTime)}</p>
              </div>
              <div>
                <h3 className="font-semibold">Total Relax Time</h3>
                <p>{formatTime(totalRelaxTime)}</p>
              </div>
            </div>
            <div className="w-full h-8 bg-gray-200 rounded-full overflow-hidden">
              <div
                className="h-full bg-orange-500"
                style={{ width: `${workPercentage}%` }}
              ></div>
            </div>
            <div className="flex justify-between text-sm">
              <span>Work: {workPercentage.toFixed(1)}%</span>
              <span>Relax: {relaxPercentage.toFixed(1)}%</span>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Activity Graph</h3>
              <svg width={graphWidth} height={graphHeight} className="mx-auto">
                {sortedEntries.map((entry, index) => {
                  const x = ((entry.startTime.getTime() - startTime.getTime()) / 1000 / totalDuration) * graphWidth
                  const height = (entry.duration / totalDuration) * maxBarHeight
                  return (
                    <rect
                      key={index}
                      x={x}
                      y={graphHeight - height}
                      width={barWidth}
                      height={height}
                      fill={entry.mode === 'work' ? '#f97316' : '#22c55e'}
                    />
                  )
                })}
              </svg>
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                <span>{startTime.toLocaleTimeString()}</span>
                <span>{endTime.toLocaleTimeString()}</span>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Timeline</h3>
              <div className="space-y-2 max-h-40 overflow-y-auto">
                {timeEntries.map((entry, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <span className={`font-medium ${entry.mode === 'work' ? 'text-orange-600' : 'text-green-600'}`}>
                      {entry.mode === 'work' ? 'Work' : 'Relax'}
                    </span>
                    <span>{entry.startTime.toLocaleTimeString()}</span>
                    <span>{Math.floor(entry.duration / 60)} minutes</span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Tasks</h3>
              <div className="space-y-2">
                <div>
                  <h4 className="font-medium">Completed Tasks ({completedTasks.length})</h4>
                  <ul className="list-disc list-inside">
                    {completedTasks.map(task => (
                      <li key={task.id} className="text-sm">{task.text}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium">Incomplete Tasks ({incompleteTasks.length})</h4>
                  <ul className="list-disc list-inside">
                    {incompleteTasks.map(task => (
                      <li key={task.id} className="text-sm">{task.text}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-start p-4">
      <div className="w-full max-w-md mx-auto">
        <div className="flex justify-between items-center mb-4">
          <Logo />
          <div className="flex space-x-2">
            <button
              onClick={() => setShowFika(true)}
              className="p-2 rounded-md bg-white text-gray-800 hover:bg-gray-100"
            >
              <Coffee className="h-5 w-5" />
            </button>
            <button
              onClick={() => setShowReport(!showReport)}
              className="p-2 rounded-md bg-white text-gray-800 hover:bg-gray-100"
            >
              <BarChart className="h-5 w-5" />
            </button>
          </div>
        </div>
        <TimerCard 
          duration={workDuration} 
          setDuration={setWorkDuration} 
          color="orange"
          timerMode="work"
        />
        <TimerCard 
          duration={relaxDuration} 
          setDuration={setRelaxDuration} 
          color="green"
          timerMode="relax"
        />
        <div className="mt-4 max-h-[calc(100vh-400px)] overflow-hidden flex flex-col bg-white rounded-lg border shadow-sm">
          <div className="p-3 pb-2">
            <div className="text-lg font-semibold">Todo List</div>
          </div>
          <div className="flex-grow overflow-y-auto p-3">
            <form onSubmit={handleAddTask} className="flex mb-2">
              <input
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                placeholder="Add a new task"
                className="flex-grow mr-2 px-3 py-2 border rounded-md"
                ref={todoInputRef}
              />
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Add</button>
            </form>
            <ul className="space-y-1">
              {tasks.map((task) => (
                <li key={task.id} className="flex items-center justify-between bg-gray-100 p-2 rounded-md text-sm">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`task-${task.id}`}
                      checked={task.completed}
                      onChange={() => handleToggleTask(task.id)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label
                      htmlFor={`task-${task.id}`}
                      className={`${task.completed ? 'line-through text-gray-500' : ''} truncate max-w-[180px]`}
                    >
                      {task.text}
                    </label>
                  </div>
                  <button
                    onClick={() => handleDeleteTask(task.id)}
                    className="text-red-500 hover:text-red-700"
                    aria-label={`Delete task: ${task.text}`}
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {showFika && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-full max-w-md p-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Fika Time!</h2>
            <p className="mb-4">Take a break and enjoy your fika for 1 hour.</p>
            <div className="flex justify-end space-x-2">
              <button onClick={() => setShowFika(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">Cancel</button>
              <button onClick={handleFika} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Start Fika</button>
            </div>
          </div>
        </div>
      )}
      {showReport && <DailyReport />}
    </div>
  )
}